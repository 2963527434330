
<div class="home">
  <section class="bg-gray">
    <SkipNav skipid="#main" />
    <header>
      <TopBarApp
        ref="topbar"
      />
    </header>

    <!-- Available Collections Page -->
    <main id="main" class="test-controller-container container">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="start-test-content">
            <h1 class="visually-hidden">Available Collections Page</h1>
            <div class="card">
              <div class="card-header" aria-hidden="true">
                Available Collections
              </div>
              <div class="card-body">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Title</th>
                      <th scope="col">Items</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="test in tests" v-bind:key="test.id">
                      <th scope="row">
                        {{ test.id }}
                      </th>
                      <td class="table-cell router-link">
                        <router-link :to="{ name: 'Start', params: { id: test.id } }">
                          {{ test.title }}
                        </router-link>
                      </td>
                      <td>
                        {{ test.count }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

  </section>
</div>
