
<div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasSettingsPanel" aria-labelledby="offcanvasSettingsLabel" style="width: 280px;">

  <div class="offcanvas-header pb-2">
    <span class="offcanvas-title fs-5 fw-semibold" id="offcanvasSettingsLabel">Settings</span>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body pt-1">
    <div class="flex-shrink-0 p-1 bg-white">
      <ul id="settingsDropdown" class="list-unstyled ps-0">
        <li class="mb-1">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#colors-collapse" aria-expanded="false">
            Colors
          </button>
          <div class="collapse" id="colors-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:default')">Default
                <span class="ms-1 checkmark menu-container-checked" data-color="colors:default">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:default-reverse')">Default Reverse
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:default-reverse">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:highcontrast')">High Contrast
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:highcontrast">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:highcontrast-reverse')">High Contrast Reverse
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:highcontrast-reverse">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:blackrose')">Black on Rose
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:blackrose">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:roseblack')">Rose on Black
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:roseblack">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:mgraydgray')">Medium Gray on Dark Gray
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:mgraydgray">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:dgraymgray')">Dark Gray on Medium Gray
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:dgraymgray">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:yellowblue')">Yellow on Blue
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:yellowblue">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:blueyellow')">Blue on Yellow
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:blueyellow">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:blackcyan')">Black on Cyan
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:blackcyan">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:cyanblack')">Cyan on Black
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:cyanblack">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:blackcream')">Black on Cream
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:blackcream">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded color-item" href="#" @click.prevent="handleMenuClick('colors:creamblack')">Cream on Black
                <span class="ms-1 checkmark menu-container-unchecked" data-color="colors:creamblack">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
            </ul>
          </div>
        </li>

        <li class="border-top my-2"></li>

        <li class="mb-1">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#pnp-collapse" aria-expanded="false">
            PNP Settings
          </button>
          <div class="collapse" id="pnp-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li class="mb-1 mt-2 ms-3">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#glossary-collapse" aria-expanded="false">
                  Glossary
                </button>
                <div class="collapse mt-1" id="glossary-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a class="link-dark rounded glossary-item" href="#" @click.prevent="handleMenuClick('glossary:off')">Off
                      <span ref="menuItemGlossaryOff" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded glossary-item" href="#" @click.prevent="handleMenuClick('glossary:on')">On
                      <span ref="menuItemGlossaryOn" class="ms-1 checkmark menu-container-checked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="mb-1 mt-2 ms-3">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#kt-collapse" aria-expanded="false">
                  Keyword Translation
                </button>
                <div class="collapse mt-1" id="kt-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:off')">Off
                      <span ref="menuItemKtLangOff" class="ms-1 checkmark menu-container-checked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:ar')">Arabic
                      <span ref="menuItemKtLangAR" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:my')">Burmese
                      <span ref="menuItemKtLangMY" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:yue')">Cantonese
                      <span ref="menuItemKtLangYUE" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:en')">English
                      <span ref="menuItemKtLangEN" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:tl')">Filipino (Ilokano + Tagalog)
                      <span ref="menuItemKtLangTL" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:de')">German
                      <span ref="menuItemKtLangDE" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:hmn')">Hmong
                      <span ref="menuItemKtLangHMN" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:ja')">Japanese
                      <span ref="menuItemKtLangJA" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:ko')">Korean
                      <span ref="menuItemKtLangKO" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:cmn')">Mandarin
                      <span ref="menuItemKtLangCMN" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:pa')">Punjabi
                      <span ref="menuItemKtLangPA" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:ru')">Russian
                      <span ref="menuItemKtLangRU" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:so')">Somali
                      <span ref="menuItemKtLangSO" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:es')">Spanish
                      <span ref="menuItemKtLangES" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:uk')">Ukrainian
                      <span ref="menuItemKtLangUK" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded kt-item" href="#" @click.prevent="handleMenuClick('ktlang:vi')">Vietnamese
                      <span ref="menuItemKtLangVI" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="mb-1 mt-2 ms-3">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#illgloss-collapse" aria-expanded="false">
                  Illustrated Glossary
                </button>
                <div class="collapse mt-1" id="illgloss-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li><a class="link-dark rounded glossary-item" href="#" @click.prevent="handleMenuClick('sbacGlossaryIllustration:off')">Off
                      <span ref="menuItemGlossaryIllustrationOff" class="ms-1 checkmark menu-container-checked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                    <li><a class="link-dark rounded glossary-item" href="#" @click.prevent="handleMenuClick('sbacGlossaryIllustration:on')">On
                      <span ref="menuItemGlossaryIllustrationOn" class="ms-1 checkmark menu-container-unchecked">
                        <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                      </span></a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <li class="border-top my-2"></li>

        <li class="mb-2">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#container-collapse" aria-expanded="false">
            Container Width
          </button>
          <div class="collapse" id="container-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a class="link-dark rounded" href="#" @click.prevent="handleMenuClick('container:fluid')">QTI 3 Player - Full Width
                <span ref="menuItemContainerFluid" class="ms-1 menu-container-checked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded" href="#" @click.prevent="handleMenuClick('container:responsive')">QTI 3 Player - Responsive
                <span ref="menuItemContainerResponsive" class="ms-1 menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
            </ul>
          </div>
        </li>

        <li class="mb-1">
          <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#padding-collapse" aria-expanded="false">
            Container Padding
          </button>
          <div class="collapse" id="padding-collapse">
            <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:0')">QTI 3 Player - Padding 0
                <span ref="menuItemContainerPadding0" class="ms-1 checkmark menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:1')">QTI 3 Player - Padding 1
                <span ref="menuItemContainerPadding1" class="ms-1 checkmark menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:2')">QTI 3 Player - Padding 2
                <span ref="menuItemContainerPadding2" class="ms-1 checkmark menu-container-checked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:3')">QTI 3 Player - Padding 3
                <span ref="menuItemContainerPadding3" class="ms-1 checkmark menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:4')">QTI 3 Player - Padding 4
                <span ref="menuItemContainerPadding4" class="ms-1 checkmark menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
              <li><a class="link-dark rounded padding-item" href="#" @click.prevent="handleMenuClick('containerpadding:5')">QTI 3 Player - Padding 5
                <span ref="menuItemContainerPadding5" class="ms-1 checkmark menu-container-unchecked">
                  <img src="@/assets/check-solid.svg" class="bi" width="14" height="14"/>
                </span></a>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </div>

  </div>
</div>
