
<div class="test-controller-nav">
  <button ref="btnPrev" type="button" :disabled="prevButtonDisabled" @click="handlePrevItem" class="btn btn-sm btn-primary pull-left me-2">Prev</button>
  <button ref="btnNext" type="button" :disabled="nextButtonDisabled" @click="handleNextItem" class="btn btn-sm btn-primary">Next</button>
  <div class="float-end m-auto">
    <div class="progress position-relative" style="height: 20px;width: 100px;">
      <div class="progress-bar" role="progressbar" v-bind:style="styleObject" :aria-valuenow="computePercentage" :aria-valuemin="0" :aria-valuemax="maxItems"></div>
      <span class="justify-content-center d-flex position-absolute w-100">{{ progressText }}</span>
    </div>
  </div>
</div>
