
<section>
  <SkipNav skipid="#main" />
  <header>
    <TopBar
      ref="topbar"
    />
  </header>

  <!-- Item Canvas -->
  <main id="main" class="test-controller-container container-fluid">
    <div class="test-controller-content">
      <h1 class="visually-hidden">Item {{currentItem+1}} of {{maxItems}}</h1>

      <Qti3Player
        ref="qti3player"
        :container-class="containerClass"
        :container-padding-class="containerPaddingClass"
        :color-class="colorClass"
        suppress-alert-messages
        suppress-invalid-response-messages
        @notifyQti3PlayerReady="handlePlayerReady"
        @notifyQti3ItemReady="handleItemReady"
        @notifyQti3SuspendAttemptCompleted="handleSuspendAttemptCompleted"
        @notifyQti3EndAttemptCompleted="handleEndAttemptCompleted"
        @notifyQti3ItemAlertEvent="displayItemAlertEvent"
        @notifyQti3ItemCatalogEvent="handleItemCatalogEvent"
      />

    </div>
  </main>

  <footer class="navbar bg-dark navbar-top-shadow">
    <BottomBar
      ref="bottombar"
      :is-previous-disabled="isBtnPreviousDisabled"
      :is-next-disabled="isBtnNextDisabled"
      :current-item="currentItem"
      :max-items="maxItems"
      @previousItem="handlePrevItem"
      @nextItem="handleNextItem"
    />
  </footer>

  <SettingsPanel
    @menuColorClick="handleMenuColorClick"
    @menuContainerClick="handleMenuContainerClick"
    @menuContainerPaddingClick="handleMenuContainerPaddingClick"
    @menuPnpChange="handlePnpChange"
  />
</section>
