
<div class="start">
  <section class="bg-gray">
    <SkipNav skipid="#main" />
    <header>
      <TopBarApp
        ref="topbar"
      />
    </header>

    <!-- Start Test Page -->
    <main id="main" class="test-controller-container container-fluid">
      <div class="start-test-content">
        <h1 class="visually-hidden">Start Test Page</h1>
        <div v-for="(test,index) in tests" :key="index">
          <div v-if="test.id == id">
            <StartPanel
              :title="test.title"
              :description="test.description"
              :max-items="test.count"
              @start="handleStart(index)"
            />
          </div>
        </div>
      </div>
    </main>

  </section>
</div>
