
<nav class="navbar navbar-expand-sm navbar-light bg-light navbar-bottom-shadow">
  <div class="container-fluid">
    <div class="navbar-brand router-link">
      <router-link :to="{ name: 'Home', params: { } }">
        <img src="@/assets/amp-up-io.svg" class="me-2" width="32" height="32" alt=""/>
        Test Runner
      </router-link>
    </div>
  </div>
</nav>
